import React from 'react';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import QueueAnim from 'rc-queue-anim';
import { Carousel as AntCarousel, Row, Col } from 'antd';
import TweenOne from 'rc-tween-one';
import Children from 'rc-tween-one/lib/plugin/ChildrenPlugin';
import { db } from "./firebase"

TweenOne.plugins.push(Children);

class Feature6 extends React.PureComponent {
  constructor(props) {
    super(props);
    this.carouselRef = React.createRef();
    this.state = {
      current: 0,
      meet: 0,
      members: 0,
      views: 0

    };

  }

  componentDidMount() {

    db.collection("STATS").doc("data").collection("meet")
    .get().then((snapShot) => {

      var counter = 0
      for (const doc of snapShot.docs){
        const num = doc.get("num")
        counter += num
      }

      this.setState({meet: counter.toString().replace(/[^0-9.-]/g, '')})

    })

    db.collection("STATS").doc("data")
    .collection("members")
    .get().then((snapShot) => {

      var counter = 0
      for (const doc of snapShot.docs){
        const num = doc.get("num")
        counter += num
      }

      this.setState({members: counter.toString().replace(/[^0-9.-]/g, '')})

    })

    db.collection("STATS").doc("data")
    .collection("views")
    .get().then((snapShot) => {

      var counter = 0
      for (const doc of snapShot.docs){
        const num = doc.get("num")
        counter += num
      }

      counter = counter / 1000000

      this.setState({views: counter.toString().replace(/[^0-9.-]/g, '')})
      

    })

  }

  getState = (i) => {
    switch (i) {
      case 0:
        return this.state.meet;
      case 1:
        return this.state.members;
      case 2:
        return this.state.views;
      default:
        return null;
    }
  }

  onTitleClick = (_, i) => {
    const carouselRef = this.carouselRef.current.childRefs.carousel;
    carouselRef.goTo(i);
  };

  onBeforeChange = (_, newIndex) => {
    this.setState({
      current: newIndex,
    });
  };

  getChildrenToRender = (dataSource) => {
    const { current } = this.state;
    const { Carousel } = dataSource;
    const {
      titleWrapper,
      children: childWrapper,
      wrapper,
      ...carouselProps
    } = Carousel;

    const {
      barWrapper,
      title: titleChild,
      ...titleWrapperProps
    } = titleWrapper;
    const titleToRender = [];

    const childrenToRender = childWrapper.map((item, ii) => {
      const { title, children, ...itemProps } = item;
      titleToRender.push(
        <div
          {...title}
          key={ii.toString()}
          onClick={(e) => {
            this.onTitleClick(e, ii);
          }}
          className={
            ii === current ? `${title.className || ''} active` : title.className
          }
        >
          {title.children}
        </div>
      );
      const childrenItem = children.map(($item, i) => {
        const { number, children: child, ...childProps } = $item;


        const { unit, toText, ...numberProps } = number;

        return (
          <Col {...childProps} key={i.toString()}>
            <TweenOne
              {...numberProps}
              animation={{
                Children: {
                  value: parseFloat(this.getState(i)),
                  floatLength:
                    parseFloat(this.getState(i)) -
                      Math.floor(parseFloat(this.getState(i))) >
                    0
                      ? 2
                      : 0,
                  formatMoney: true,
                },
                duration: 1000,
                delay: 300,
                ease: 'easeInOutCirc',
              }}
              component="span"
            >
              0
            </TweenOne>
            {unit && <span {...unit}>{unit.children}</span>}
            <p {...child}>{child.children}</p>
          </Col>
        );
      });
      return (
        <div key={ii.toString()}>
          <QueueAnim type="bottom" component={Row} {...itemProps}>
            {childrenItem}
          </QueueAnim>
        </div>
      );
    });

    const width = 100 / childrenToRender.length;
    return (
      <QueueAnim
        key="queue"
        leaveReverse
        type="bottom"
        delay={[0, 100]}
        {...wrapper}
        
        ref={this.carouselRef}
      >
        <div {...titleWrapperProps} key="title" >
          <div {...titleChild}>
            {titleToRender}
            <div
              {...barWrapper}
              style={{
                width: `${width}%`,
                left: `${width * current}%`,
              }}
            >
              <em {...barWrapper.children} />
            </div>
          </div>
        </div>
        <AntCarousel
          {...carouselProps}
          key="carousel"
          infinite={false}
          beforeChange={this.onBeforeChange}
        >
          {childrenToRender}
        </AntCarousel>
      </QueueAnim>
    );
  };

  render() {
    const { dataSource, isMobile, ...props } = this.props;
    return (
      <div {...props} {...dataSource.wrapper}>
        <div>
          <OverPack {...dataSource.OverPack}>
            {this.getChildrenToRender(dataSource)}
          </OverPack>
        </div>
      </div>
    );
  }
}
export default Feature6;
