import React from 'react';

export const Nav00DataSource = {
  wrapper: { className: 'header0 home-page-wrapper' },
  page: { className: 'home-page' },
  logo: {
    className: 'header0-logo',
    children: 'https://firebasestorage.googleapis.com/v0/b/rentb-sg/o/RentBabeCoin.svg?alt=media&token=deb30771-e99b-4b91-b08c-161504713039',
  },
  Menu: {
    className: 'header0-menu',
    children: [
      {
        name: 'item0',
        className: 'header0-item',
        children: {
          href: '#',
          children: [{ children: 'Home', name: 'text' }],
        },
        subItem: [
      
        ],
      },
      {
        name: 'item1',
        className: 'header0-item',
        children: {
          href: '#Content0_0',
          children: [{ children: 'About', name: 'text' }],
        },
      },
      {
        name: 'item2',
        className: 'header0-item',
        children: {
          href: '#Content5_0',
          children: [{ children: 'Buy RBC', name: 'text' }],
        },
      }
    ],
  },
  mobileMenu: { className: 'header0-mobile-menu' },
};

export const Banner01DataSource = {
  wrapper: { className: 'banner0' },
  textWrapper: { className: 'banner0-text-wrapper' },
  title: {
    className: 'banner0-title',
    children: "https://firebasestorage.googleapis.com/v0/b/rentb-sg/o/rentbabecoinfont2.svg?alt=media&token=ab20c691-a307-4aad-ae1b-beb9d10e115c",
  },
  content: {
    className: 'banner0-content',
    children: 'RentBabe Coin (RBC) | Date with coins',
  },
  button: { className: 'banner0-button', children: 'Buy RBC',  href: '#Content5_0' },
  button2: { className: 'banner0-button', children: 'View Live Product',  href: 'https://www.rentbabe.com', target : "_blank" }
};

export const Content00DataSource = {
  wrapper: { className: 'home-page-wrapper content0-wrapper' },
  page: { className: 'home-page content0' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [{ name: 'title', children: 'What is RentBabe?' }],
    description: 'RentBabe is a platform that allows you to rent a date or companionship instantly. While none of us could have imagined it at the time, this might be a concept that would start a revolution. Our goal and vision is to launch RentBabe globally with the help of decentralised network, targeting bigger countries like China, Taiwan and especially Japan.'
  },

  titleWrapper2: {
    className: 'title-wrapper',
    children: [{ name: 'title', children: 'What is the Coin used for?' }],
    description: '',
    description1: "1) RBC is used for fund raising to support and further develop RentBabe platform to launch globally",
    description2: "2) RBC is a Utility Token. The more token you hold the more preveledge you have on using the platform, such as up to 10% discount.", 
  },
  childWrapper: {
    className: 'content0-block-wrapper',
    children: [
      {
        name: 'block0',
        
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                'https://firebasestorage.googleapis.com/v0/b/rentb-sg/o/crowdfunding.svg?alt=media&token=9b6bc74e-5233-4722-99b1-f8c95f746e16',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: 'Fund raising',
            },
            { name: 'content', children: 'RBC is used for fund raising to support and further develop RentBabe platform to launch globally.' },
          ],
        },
      },
      {
        name: 'block1',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children: 'https://firebasestorage.googleapis.com/v0/b/rentb-sg/o/price.svg?alt=media&token=66e8bf53-54b6-4aa1-9a21-96821f7f85aa',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: 'Token Utility',
            },
            {
              name: 'content',
              children: 'The more token you hold the more privilege you have on using the platform, such as up to 10% discount, high priority bookings, etc.',
            },
          ],
        },
      },
      {
        name: 'block2',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                'https://firebasestorage.googleapis.com/v0/b/rentb-sg/o/growing.svg?alt=media&token=56bbf61d-d2da-4e71-8c67-cac8424d661a',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: 'Token Value',
            },
            {
              name: 'content',
              children: 'Many traditional platforms are giving users digital points that dont worth any value. However, RBC is valuable as users are holding and buying it to get more privileges from the platform.',
            },
          ],
        },
      },
    ],
  },
};

export const Feature60DataSource = {
  wrapper: { className: 'home-page-wrapper feature6-wrapper' },
  OverPack: { className: 'home-page feature6', playScale: 0.3 },
  Carousel: {
    className: 'feature6-content',
    dots: false,
    wrapper: { className: 'feature6-content-wrapper' },
    titleWrapper: {
      className: 'feature6-title-wrapper',
      barWrapper: {
        className: 'feature6-title-bar-wrapper',
        children: { className: 'feature6-title-bar' },
      },
      title: { className: 'feature6-title' },
    },
    children: [
      {
        title: { className: 'feature6-title-text', children: 'RentBabe Metrics' },
        className: 'feature6-item',
        name: 'block0',
        children: [
          {
            md: 8,
            xs: 24,
            className: 'feature6-number-wrapper',
            name: 'child0',
            number: {
              className: 'feature6-number',
              unit: { className: 'feature6-unit', children: '' },
              toText: true,
              children: "",
            },
            children: { className: 'feature6-text', children: 'Meet-ups' },
          },
          {
            md: 8,
            xs: 24,
            className: 'feature6-number-wrapper',
            name: 'child1',
            number: {
              className: 'feature6-number',
              unit: { className: 'feature6-unit', children: '' },
              toText: true,
              children: "",
            },
            children: { className: 'feature6-text', children: 'Members' },
          },
          {
            md: 8,
            xs: 24,
            className: 'feature6-number-wrapper',
            name: 'child2',
            number: {
              className: 'feature6-number',
              unit: { className: 'feature6-unit', children: 'M' },
              toText: true,
              children: "",
            },
            children: { className: 'feature6-text', children: 'Profile views' },
          },
        ],
      }
      // ,
      // {
      //   title: { className: 'feature6-title-text', children: '服务指标' },
      //   className: 'feature6-item',
      //   name: 'block1',
      //   children: [
      //     {
      //       md: 8,
      //       xs: 24,
      //       name: 'child0',
      //       className: 'feature6-number-wrapper',
      //       number: {
      //         className: 'feature6-number',
      //         unit: { className: 'feature6-unit', children: '万' },
      //         toText: true,
      //         children: '116',
      //       },
      //       children: { className: 'feature6-text', children: '模型数据' },
      //     },
      //     {
      //       md: 8,
      //       xs: 24,
      //       name: 'child1',
      //       className: 'feature6-number-wrapper',
      //       number: {
      //         className: 'feature6-number',
      //         unit: { className: 'feature6-unit', children: '亿' },
      //         toText: true,
      //         children: '1.17',
      //       },
      //       children: { className: 'feature6-text', children: '模型迭代数量' },
      //     },
      //     {
      //       md: 8,
      //       xs: 24,
      //       name: 'child2',
      //       className: 'feature6-number-wrapper',
      //       number: {
      //         className: 'feature6-number',
      //         unit: { className: 'feature6-unit', children: '亿' },
      //         toText: true,
      //         children: '2.10',
      //       },
      //       children: { className: 'feature6-text', children: '训练样本数量' },
      //     },
      //   ],
      // },
    ],
  },
};

export const Content50DataSource = {
  wrapper: { className: 'home-page-wrapper content5-wrapper' },
  page: { className: 'home-page content5' },
  OverPack: { playScale: 0.3, className: '' },
  buyButton: {
    title: " Buy RentBabe Coin on Uniswap",
    type: "primary",
    fontWeight: "bolder", 
    target: "_blank",
    href: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x8a90c646c35f81934138e7f68f7d4e06b36e61e5&use=V2"
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      { name: 'title', children: 'Buy RentBabe Coin', className: 'title-h1' },
      {
        name: 'content',
        className: 'title-content',
        children: 'Get a RBC easily with Uniswap',
      },
    ],
  },
  block: {
    className: 'content5-img-wrapper',
    gutter: 16,
    children: [
      {
        name: 'block0',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              'https://firebasestorage.googleapis.com/v0/b/rentb-sg/o/wallet.svg?alt=media&token=f868a4cb-ebad-4c43-9e1c-6ef58684e7c6',
          },
          content: { children: 'Set up your a wallet and buy ETH' },
        },
      },
      {
        name: 'block1',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              'https://firebasestorage.googleapis.com/v0/b/rentb-sg/o/RentBabeCoin%20only.svg?alt=media&token=d559af16-a533-43b4-bbbf-b95a5d458ffb',
          },
          content: { children: 'Swap for RBC' },
        },
      },
      {
        name: 'block2',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              'https://firebasestorage.googleapis.com/v0/b/rentb-sg/o/people.svg?alt=media&token=0d146413-33c1-4075-b3ca-e7935248710c',
          },
          content: { children: 'Rent a date' },
        },
      },

      {
        name: 'block3',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              'https://firebasestorage.googleapis.com/v0/b/rentb-sg/o/paper.svg?alt=media&token=6d90c36e-b458-4c5e-87c3-9b9e4f5270d2',
          },
          content: { children: 'White Paper'},
        },
      }
    ],
  },
};
export const Content30DataSource = {
  wrapper: { className: 'home-page-wrapper content3-wrapper' },
  page: { className: 'home-page content3' },
  OverPack: { playScale: 0.3 },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: 'RentBabe Metrics',
        className: 'title-h1',
      },
      {
        name: 'content',
        className: 'title-content',
        children: '',
      },
    ],
  },
  block: {
    className: 'content3-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/ScHBSdwpTkAHZkJ.png',
          },
          textWrapper: { className: 'content3-text' },
          title: { className: 'content3-title', children: 'Meetups' },
          content: {
            className: 'content3-content',
            children:
              '1000',
          },
        },
      },
      {
        name: 'block1',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/NKBELAOuuKbofDD.png',
          },
          textWrapper: { className: 'content3-text' },
          title: { className: 'content3-title', children: 'Members' },
          content: {
            className: 'content3-content',
            children:
              '213',
          },
        },
      },
      {
        name: 'block2',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/xMSBjgxBhKfyMWX.png',
          },
          textWrapper: { className: 'content3-text' },
          title: { className: 'content3-title', children: '云监控' },
          content: {
            className: 'content3-content',
            children:
              '分布式云环境集中监控，统一资源及应用状态视图，智能分析及故障定位。',
          },
        },
      },
      {
        name: 'block3',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/MNdlBNhmDBLuzqp.png',
          },
          textWrapper: { className: 'content3-text' },
          title: { className: 'content3-title', children: '移动' },
          content: {
            className: 'content3-content',
            children:
              '一站式移动金融APP开发及全面监控；丰富可用组件，动态发布和故障热修复。',
          },
        },
      },
      {
        name: 'block4',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/UsUmoBRyLvkIQeO.png',
          },
          textWrapper: { className: 'content3-text' },
          title: { className: 'content3-title', children: '分布式中间件' },
          content: {
            className: 'content3-content',
            children:
              '金融级联机交易处理中间件，大规模分布式计算机，数万笔/秒级并发能力，严格保证交易数据统一性。',
          },
        },
      },
      {
        name: 'block5',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/ipwaQLBLflRfUrg.png',
          },
          textWrapper: { className: 'content3-text' },
          title: { className: 'content3-title', children: '大数据' },
          content: {
            className: 'content3-content',
            children:
              '一站式、全周期大数据协同工作平台，PB级数据处理、毫秒级数据分析工具。',
          },
        },
      },
    ],
  },
};
export const Footer10DataSource = {
  wrapper: { className: 'home-page-wrapper footer1-wrapper' },
  OverPack: { className: 'footer1', playScale: 0.2 },
  block: {
    className: 'home-page',
    gutter: 0,
    children: [
      {
        name: 'block0',
        xs: 24,
        md: 6,
        className: 'block',
        title: {
          className: 'logo',
          children:
            'https://firebasestorage.googleapis.com/v0/b/rentb-sg/o/RentBabeCoin.svg?alt=media&token=deb30771-e99b-4b91-b08c-161504713039',
        },
        childWrapper: {
          className: 'slogan',
          children: [
            {
              name: 'content0',
              children: 'RentBabe Coin | (RBC)',
            },
          ],
        },
      },
      {
        name: 'block1',
        xs: 24,
        md: 6,
        className: 'block',
        title: { children: 'Social' },
        childWrapper: {
          children: [
            { name: 'link0', children: 'Twitter', href: "https://twitter.com/rentbabecoin" },
            { name: 'link1',  children: 'Telegram' , href: "https://t.me/RentBabeGlobalCoinSupport" },
            { name: 'link2',  children: 'Discord'  , href: "https://discord.gg/Xn2hDkvCfD" }
          ],
        },
      },
      {
        name: 'block2',
        xs: 24,
        md: 6,
        className: 'block',
        title: { children: 'Support' },
        childWrapper: {
          children: [
            { name: 'link0', children: 'support@RentBabeCoin.com' , href : "mailto: support@RentBabeCoin.com"},
            { name: 'link1', children: 'Etherscan' , href : "https://etherscan.io/token/0x8a90c646c35f81934138e7f68f7d4e06b36e61e5"},
            { name: 'link2', children: 'White paper' , href : "/RBC.pdf"}
          ],
        },
      }
    ],
  },
  copyrightWrapper: { className: 'copyright-wrapper' },
  copyrightPage: { className: 'home-page' },
  copyright: {
    className: 'copyright',
    children: (
      <span>
        ©2021 by <a >RentBabe Coin</a> All Rights
        Reserved
      </span>
    ),
  },
};