import React from 'react';
import { Button } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import QueueAnim from 'rc-queue-anim';
import TweenOne from 'rc-tween-one';
import { isImg } from './utils';
import '../css/Banner0.css'

class Banner extends React.PureComponent {
  render() {
    const { ...currentProps } = this.props;
    const { dataSource } = currentProps;
    delete currentProps.dataSource;
    delete currentProps.isMobile;
    
    return (
      <div>

        <div {...currentProps} {...dataSource.wrapper} >

        <div className="star-containers" >

          <div className="star1 star" style={{left: "91%" , top: "46px", animationDuration : "3.8s" , height: "3px", width: "3px"}}></div>
          <div className="star1 star" style={{left: "23%" , top: "46px" , animationDuration : "4.45s" , height: "3.2px", width: "3.2px"}}></div>
          <div className="star1 star" style={{left: "54%" , top: "35px" , animationDuration : "4.7s" , height: "3.6px", width: "3.6px"}}></div>
          <div className="star1 star" style={{left: "78%" , top: "333px" , animationDuration : "3.5s" , height: "2.6px", width: "2.6px"}}></div>
          <div className="star1 star" style={{left: "68%" , top: "140px" , animationDuration : "1.8s"}}></div>
          <div className="star1 star" style={{left: "97%" , top: "167px" , animationDuration : "4s"}}></div>
          <div className="star1 star" style={{left: "9%" , top: "345px" , animationDuration : "4.7s" , height: "2.4px", width: "2.4px"}}></div>
          <div className="star1 star" style={{left: "3%" , top: "366px" , animationDuration : "2.1s"}}></div>
          <div className="star1 star" style={{left: "86%" , top: "100px" , animationDuration : "2.3s"}}></div>
          <div className="star1 star" style={{left: "56%" , top: "481px" , animationDuration : "4.3s"}}></div>
          <div className="star1 star" style={{left: "88%" , top: "335px" , animationDuration : "3.6s"}}></div>
          <div className="star1 star" style={{left: "7%" , top: "134px" , animationDuration : "3.3s"}}></div>
          <div className="star1 star" style={{left: "92%" , top: "125px" , animationDuration : "1.6s"}}></div>
          <div className="star1 star" style={{left: "26%" , top: "501px" , animationDuration : "3.1s"}}></div>
          <div className="star1 star" style={{left: "79%" , top: "450px" , animationDuration : "2.4s"}}></div>
          <div className="star1 star" style={{left: "7%" , top: "591px" , animationDuration : "3.1s"}}></div>
          <div className="star1 star" style={{left: "74%" , top: "438px" , animationDuration : "3.43s"}}></div>
          <div className="star1 star" style={{left: "25%" , top: "411px" , animationDuration : "3.3s" , height: "3.3px", width: "3.3px"}}></div>
          <div className="star1 star" style={{left: "97%" , top: "34px" , animationDuration : "2.8s" , height: "4.1px", width: "4.1px"}}></div>
          <div className="star1 star" style={{left: "96%" , top: "565px" , animationDuration : "4.4s" , height: "3.9px", width: "3.9px"}}></div>
          <div className="star1 star" style={{left: "5%" , top: "31px" , animationDuration : "3.1s" , height: "2.9px", width: "2.9px"}}></div>
          <div className="star1 star" style={{left: "9%" , top: "238px" , animationDuration : "3.43s"}}></div>
          <div className="star1 star" style={{left: "88%" , top: "11px" , animationDuration : "3.1s"}}></div>
          <div className="star1 star" style={{left: "90%" , top: "338px" , animationDuration : "2.4s"}}></div>

        </div>
  
          <QueueAnim
            key="QueueAnim"
            type={['bottom', 'top']}
            delay={200}
            {...dataSource.textWrapper}>

            <div style={{ width: "100%"}}  
            key="title" {...dataSource.title}  >
              {typeof dataSource.title.children === 'string' &&
              dataSource.title.children.match(isImg) ? (
                <img src= {dataSource.title.children} width="100%" alt="img" />
              ) : (
                dataSource.title.children
              )}

            <div style={{ width: "100%", fontSize: "19px"}} key="content" {...dataSource.content}>
              <h1 style={{  fontSize: "19px" , color: "white"}}>{dataSource.content.children}</h1>
            </div>

            <Button ghost key="button" {...dataSource.button} style={{ marginRight: "8px"}}>
              {dataSource.button.children}
            </Button>
           
            <Button ghost key="button" {...dataSource.button2} style={{ marginLeft: "8px"}}>
              {dataSource.button2.children}
            </Button>
                
            </div>

          </QueueAnim>
          <TweenOne
            animation={{
              y: '-=20',
              yoyo: true,
              repeat: -1,
              duration: 1000,
            }}
            className="banner0-icon"
            key="icon"
          >
            <DownOutlined />
          </TweenOne>
        </div>
      </div>
     
    );
  }
}
export default Banner;
