import React from 'react';
import { Row, Col, Button } from 'antd';
import { TweenOneGroup } from 'rc-tween-one';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import { getChildrenToRender } from './utils';
import '../css/Content5.css'

class Content5 extends React.PureComponent {


  openTelegram = () => {
    window.open("https://t.me/RentBabeGlobalCoinSupport" , "_blank")
  }

  openDiscord = () => {
    window.open("https://discord.gg/Xn2hDkvCfD" , "_blank")
  }

  openTwitter = () => {
    window.open("https://twitter.com/rentbabecoin" , "_blank")
  }

  getChildrenToRender = (data) =>
    data.map((item) => {
      return (
        <Col  key={item.name} {...item} onClick={() => {

          switch (item.name) {
            case "block0":
              window.open("https://metamask.io/" , "_blank")
              break;

            case "block2":
              window.open("https://rentbabe.com/" , "_blank")
              break;

            case "block1":
              window.open("https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x8a90c646c35f81934138e7f68f7d4e06b36e61e5&use=V2" , "_blank")
              break;

            case "block3":
              // href="/whitepaper.pdf" target="_blank"
              window.open("/RBC.pdf" , "_blank")
              break
          
            default:
              break;
          }
          
        }}>

          <div  className="glass-btn blue-btn">

            <a {...item.children.wrapper}>
            
            <span {...item.children.img}>
              <img src={item.children.img.children} height="100%" alt="img" />
            </span>

            <p {...item.children.content}>{item.children.content.children}</p>
            
          </a>

          </div>

        </Col>
      );
    });

  render() {
    const { ...props } = this.props;
    const { dataSource } = props;
    delete props.dataSource;
    delete props.isMobile;
    const childrenToRender = this.getChildrenToRender(
      dataSource.block.children
    );
    return (
      <div {...props} {...dataSource.wrapper}  >
        <div {...dataSource.page} >
          <div key="title" {...dataSource.titleWrapper}>
            {dataSource.titleWrapper.children.map(getChildrenToRender)}
          </div>
          <OverPack
            className={`content-template ${props.className}`}
            {...dataSource.OverPack}
          >
            <TweenOneGroup
              component={Row}
              key="ul"
              enter={{
                y: '+=30',
                opacity: 0,
                type: 'from',
                ease: 'easeInOutQuad',
              }}
              leave={{ y: '+=30', opacity: 0, ease: 'easeInOutQuad' }}
              {...dataSource.block}
            >
              {childrenToRender}

    <div  style={{width: "100%", textAlign: "center"}} >
      <div className="main-div main-div1" style={{ width : "350px"  , left : "50%" , marginLeft: "-175px"}} >

      <Button  {...dataSource.buyButton}  style={{ width : "100%"}} >

       {dataSource.buyButton.title}

      </Button>
      </div>
  
    </div>

    <br/>

    <div style={{width: "100%", textAlign: "center", marginTop: "16px"}} onClick={this.openTelegram}>
            <Button style={{marginLeft: "8px"}} shape = "circle "type="primary">
      <img style={{width: "16px" , height : "16px"}} src ="https://firebasestorage.googleapis.com/v0/b/rentb-sg/o/telegram.svg?alt=media&token=483a9579-da21-417e-b3f6-b78b3bda3b56" ></img>
    </Button>

    <Button style={{marginLeft: "8px"}} shape = "circle "type="primary" onClick={this.openTwitter}>
      <img style={{width: "16px" , height : "16px"}}  src ="https://firebasestorage.googleapis.com/v0/b/rentb-sg/o/twitter.svg?alt=media&token=7b648dfd-7358-4010-9d06-ddeb031d878f" ></img>
    </Button>

    <Button style={{marginLeft: "8px"}} shape = "circle "type="primary" onClick={this.openDiscord}>
      <img style={{width: "18px" , height : "18px"}}  src ="https://firebasestorage.googleapis.com/v0/b/rentb-sg/o/discord.svg?alt=media&token=0fc6f22f-bf10-4ca8-b41e-f5717044aa5a" ></img>
    </Button>
      
      </div>

            </TweenOneGroup>
          </OverPack>

          
        </div>
      </div>
    );
  }
}

export default Content5;