import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import 'firebase/analytics'
//import * as geoFirestore from 'geofirestore';

//rent-a-date-81735.appspot.com
const config = {
    apiKey: "AIzaSyAcm-BxZTYAhshcuDByBU9Ljzh5l1oleMM",
    authDomain: "rent-a-date-81735.firebaseapp.com",
    databaseURL: "https://rent-a-date-81735.firebaseio.com",
    projectId: "rent-a-date-81735",
    storageBucket: "rentb-sg",
    messagingSenderId: "464795139098",
    appId: "1:464795139098:web:87ce25ea4154dad390e5a3",
    measurementId: "G-K2SHMBYTJ3"
  };


firebase.initializeApp(config);

export const auth = firebase.auth();
export const db = firebase.firestore();
//export const geo = geoFirestore.initializeApp(db); 
export const storage = firebase.storage();
export const analytics = firebase.analytics();